import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={30} {...props}>
    <g fill="currentColor" data-name="vuesax/bold/crown">
      <path d="M26.466 25.015h-9.098a.687.687 0 0 1-.682-.683.687.687 0 0 1 .682-.682h9.1a.687.687 0 0 1 .682.682.687.687 0 0 1-.684.683Z" />
      <path
        data-name="Vector"
        d="m29.514 10.027-3.639 2.6a.916.916 0 0 1-1.383-.418l-1.719-4.588a.906.906 0 0 0-1.7 0l-1.73 4.576a.9.9 0 0 1-1.374.418l-3.639-2.6a.917.917 0 0 0-1.392 1.054l3.785 10.6a.9.9 0 0 0 .855.6h8.67a.915.915 0 0 0 .855-.6l3.785-10.6a.9.9 0 0 0-1.374-1.042Zm-5.322 8.4h-4.549a.682.682 0 1 1 0-1.365h4.549a.682.682 0 1 1 0 1.365Z"
      />
    </g>
  </svg>
);

export default SvgComponent;
