import * as React from "react";

const SvgComponent = (props) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="20" height="30"
	 viewBox="0 0 18 18">
<g fill="currentColor">
	<path fill="currentColor" d="M2.8204,1.0798v1.7407H1.0798V1.0798H2.8204 M3.2915,0H0.6087C0.2725,0,0,0.2725,0,0.6087v2.6829
		c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087V0.6087C3.9002,0.2725,3.6276,0,3.2915,0
		L3.2915,0z"/>
</g>
<g fill="currentColor">
	<path fill="currentColor" d="M7.5204,1.0798v1.7407H5.7797V1.0798H7.5204 M7.9915,0H5.3086C4.9725,0,4.6999,0.2725,4.6999,0.6087v2.6829
		c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087V0.6087C8.6001,0.2725,8.3276,0,7.9915,0
		L7.9915,0z"/>
</g>
<path fill="currentColor" d="M3.2915,4.6999H0.6087C0.2725,4.6999,0,4.9725,0,5.3086v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829
	c0.3361,0,0.6087-0.2725,0.6087-0.6087V5.3086C3.9002,4.9725,3.6276,4.6999,3.2915,4.6999L3.2915,4.6999z"/>
<path fill="currentColor" d="M12.6914,0h-2.6829C9.6724,0,9.3999,0.2725,9.3999,0.6087v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829
	c0.3361,0,0.6087-0.2725,0.6087-0.6087V0.6087C13.3001,0.2725,13.0275,0,12.6914,0L12.6914,0z"/>
<path fill="currentColor" d="M12.6914,4.6999h-2.6829c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087
	h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087V5.3086C13.3001,4.9725,13.0275,4.6999,12.6914,4.6999L12.6914,4.6999z"/>
<path fill="currentColor" d="M3.2915,14.0998H0.6087C0.2725,14.0998,0,14.3724,0,14.7085v2.6829C0,17.7275,0.2725,18,0.6087,18h2.6829
	c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829C3.9002,14.3724,3.6276,14.0998,3.2915,14.0998L3.2915,14.0998z"/>
<path fill="currentColor" d="M7.9915,14.0998H5.3086c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829C4.6999,17.7275,4.9725,18,5.3086,18
	h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829C8.6001,14.3724,8.3276,14.0998,7.9915,14.0998L7.9915,14.0998z"/>
<path fill="currentColor" d="M12.6914,14.0998h-2.6829c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829C9.3999,17.7275,9.6724,18,10.0085,18
	h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829C13.3001,14.3724,13.0275,14.0998,12.6914,14.0998L12.6914,14.0998z"/>
<path fill="currentColor" d="M17.3913,14.0998h-2.6829c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087
	h2.6829C17.7275,18,18,17.7275,18,17.3913v-2.6829C18,14.3724,17.7275,14.0998,17.3913,14.0998L17.3913,14.0998z"/>
<path fill="currentColor" d="M3.2915,9.3999H0.6087C0.2725,9.3999,0,9.6724,0,10.0085v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829
	c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829C3.9002,9.6724,3.6276,9.3999,3.2915,9.3999L3.2915,9.3999z"/>
<path fill="currentColor" d="M12.6914,9.3999h-2.6829c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087
	h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829C13.3001,9.6724,13.0275,9.3999,12.6914,9.3999L12.6914,9.3999z"/>
<path fill="currentColor" d="M17.3913,9.3999h-2.6829c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087
	h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829C18,9.6724,17.7275,9.3999,17.3913,9.3999L17.3913,9.3999z"/>
<g fill="currentColor">
	<path fill="currentColor" d="M16.9202,1.0798v1.7407h-1.7407V1.0798H16.9202 M17.3913,0h-2.6829c-0.3361,0-0.6087,0.2725-0.6087,0.6087
		v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829C17.7275,3.9002,18,3.6276,18,3.2915V0.6087C18,0.2725,17.7275,0,17.3913,0
		L17.3913,0z"/>
</g>
<g fill="currentColor">
	<path fill="currentColor" d="M7.5204,5.7797v1.7407H5.7797V5.7797H7.5204 M7.9915,4.6999H5.3086c-0.3361,0-0.6087,0.2725-0.6087,0.6087
		v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087V5.3086
		C8.6001,4.9725,8.3276,4.6999,7.9915,4.6999L7.9915,4.6999z"/>
</g>
<g fill="currentColor">
	<path fill="currentColor" d="M7.5204,10.4796v1.7407H5.7797v-1.7407H7.5204 M7.9915,9.3999H5.3086c-0.3361,0-0.6087,0.2725-0.6087,0.6087
		v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829c0.3361,0,0.6087-0.2725,0.6087-0.6087v-2.6829
		C8.6001,9.6724,8.3276,9.3999,7.9915,9.3999L7.9915,9.3999z"/>
</g>
<g fill="currentColor">
	<path fill="currentColor" d="M16.9202,5.7797v1.7407h-1.7407V5.7797H16.9202 M17.3913,4.6999h-2.6829
		c-0.3361,0-0.6087,0.2725-0.6087,0.6087v2.6829c0,0.3361,0.2725,0.6087,0.6087,0.6087h2.6829C17.7275,8.6001,18,8.3276,18,7.9915
		V5.3086C18,4.9725,17.7275,4.6999,17.3913,4.6999L17.3913,4.6999z"/>
</g>
</svg>
);

export default SvgComponent;
