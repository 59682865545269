import React from "react";

import client from "../lib/client";

export const uploadPhoto = async (payload) => {
  const response = await client.post("/user/profile/photo", payload);
  return response;
};

export const useUploadPhoto = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const execute = async (payload) => {
    try {
      setIsLoading(true);
      const response = await uploadPhoto(payload);
      setData(response);
      return response;
    } catch (e) {
      setError(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, data, execute };
};
