import * as React from "react";

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={30} {...props}>
    <g fill="currentColor" data-name="vuesax/bold/chart">
      <path d="M31.328 24.328h-18.48a.7.7 0 0 1-.693-.693.7.7 0 0 1 .693-.693h18.48a.7.7 0 0 1 .693.693.7.7 0 0 1-.693.693Z" />
      <path
        data-name="Vector"
        d="M20.009 7.696v16.632h4.158V7.696c0-1.016-.416-1.848-1.663-1.848h-.832c-1.247 0-1.663.832-1.663 1.848ZM13.772 13.24v11.088h3.7V13.24c0-1.016-.374-1.848-1.482-1.848h-.74c-1.108 0-1.478.832-1.478 1.848ZM26.708 17.86v6.468h3.7V17.86c0-1.016-.374-1.848-1.482-1.848h-.74c-1.108 0-1.478.832-1.478 1.848Z"
      />
    </g>
  </svg>
);

export default SvgComponent;
